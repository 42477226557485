import React, { useState, useEffect } from 'react';
import "./index.css";
const Feedback = () => {
    const [showModal, setShowModal] = useState(false);
    const [theme, setTheme] = useState({ backgroundColor: '#fff', color: '#000' });
    let currentTheme;
    useEffect(() => {
        currentTheme = localStorage.getItem('theme');
        // console.log(currentTheme);
        if (currentTheme === 'dark') {
            setTheme({ backgroundColor: '#333', color: '#fff' });
        }
        // console.log(theme)
    }, []);

    return (
        <div className="github-link-box">
            <button style={{all: 'unset', cursor: 'pointer'}} onClick={() => {
                setShowModal(true)
            }}>
                <svg d="1715477547442" className="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="4705" width="200" height="200">
                    <path
                        d="M143.992471 650.511059v181.458823l180.374588-104.448c24.877176 4.065882 81.408 8.463059 107.64047 8.463059 229.737412 0 384-170.977882 384-372.013176S623.284706 0 432.007529 0C201.938824 0 15.992471 162.966588 15.992471 364.001882c0 116.464941 44.423529 215.341176 128 286.509177z m464.022588-330.511059a47.977412 47.977412 0 1 1 0 95.924706 47.977412 47.977412 0 0 1 0-95.924706z m-192.030118 0a48.007529 48.007529 0 1 1 0 95.984941 48.007529 48.007529 0 0 1 0-95.984941z m-192 0a48.007529 48.007529 0 1 1 0.030118 96.045176 48.007529 48.007529 0 0 1-0.030118-96.045176z m176.00753 479.984941h-95.984942c76.197647 74.511059 164.773647 119.988706 288.015059 119.988706 26.202353 0 51.772235-2.349176 76.649412-6.415059L847.992471 1024v-181.519059c97.28-66.620235 160.015059-170.014118 160.015058-286.479059 0-103.815529-49.904941-197.240471-129.596235-263.559529l1.596235 27.557647c0.271059 26.142118 0 23.762824 0 47.977412 0 255.216941-201.065412 432.007529-480.015058 432.007529z"
                        fill="#A8A8A8" p-id="4706"></path>
                </svg>

            </button>
            {showModal && (
                <div style={{
                    position: 'fixed',
                    top: '20%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: currentTheme === 'light' ? 'linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(229,245,255,1) 100%)' : 'linear-gradient(135deg, rgba(55,55,55,1) 0%, rgba(25,25,25,1) 100%)',
                    color: currentTheme === 'light' ? '#333' : '#ccc', // 调整文字颜色
                    padding: '20px',
                    borderRadius: '12px',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.15)',
                    width: '320px',
                    boxSizing: 'border-box',
                    textAlign: 'center'
                }}>
                    <button style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'transparent',
                        color: currentTheme === 'light' ? '#333' : '#ccc',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        outline: 'none'
                    }} onClick={() => setShowModal(false)}>&#10005;</button>
                <h4 style={{
                    margin: '0 0 20px 0',
                    fontSize: '18px'
                }}>选择反馈类型</h4>
                <button style={{
                    background: currentTheme === 'light' ? '#6c757d' : '#343a40',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    marginRight: '10px',
                    cursor: 'pointer',
                    outline: 'none',
                    transition: 'background 0.3s ease'
                }} onClick={() => {
                    window.open("https://www.wjx.cn", "_blank");
                }}>匿名反馈
                </button>
                <button style={{
                    background: currentTheme === 'light' ? '#6c757d' : '#343a40',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    outline: 'none',
                    transition: 'background 0.3s ease'
                }} onClick={() => {
                    window.open("https://yazs.top", "_blank");
                }}>实名反馈
                </button>
            </div>


        )}
            {/*<a href="http://yazs.top" target={"_blank"} rel="noreferrer">*/}

            {/*</a>*/}
        </div>
    );
};
export default Feedback;
